
import './App.css';

const App = () => {
  return (
    <div className='landingContainer'>
      <h1 class="text">
        <span class="letter letter-1">T</span>
        <span class="letter letter-2">H</span>
        <span class="letter letter-3">I</span>
        <span class="letter letter-4">S</span>
        <span class="letter letter-5">P</span>
        <span class="letter letter-6">A</span>
        <span class="letter letter-7">G</span>
        <span class="letter letter-8">E</span>
        <span class="letter letter-9">I</span>
        <span class="letter letter-10">S</span>
        <span class="letter letter-11">U</span>
        <span class="letter letter-12">N</span>
        <span class="letter letter-13">D</span>
        <span class="letter letter-14">E</span>
        <span class="letter letter-15">R</span>
        <span class="letter letter-16">C</span>
        <span class="letter letter-17">O</span>
        <span class="letter letter-18">N</span>
        <span class="letter letter-19">S</span>
        <span class="letter letter-20">T</span>
        <span class="letter letter-21">R</span>
        <span class="letter letter-22">U</span>
        <span class="letter letter-23">C</span>
        <span class="letter letter-24">T</span>
        <span class="letter letter-25">I</span>
        <span class="letter letter-26">O</span>
        <span class="letter letter-27">N</span>
      </h1>
    </div>
  )
}

export default App;